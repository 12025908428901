"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const vue_property_decorator_1 = require("vue-property-decorator");
const login_1 = require("@/api/login");
let Password = class Password extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.form = {
            loginToken: this.userInfo.loginToken,
            password: '',
            newPassword: '',
            newAgain: ''
        };
        this.checkPwd = (rule, value, callback) => {
            if (this.form.newPassword !== this.form.newAgain) {
                return callback(new Error('The two new passwords are inconsistent.'));
            }
            else {
                callback();
            }
        };
        this.rules = {
            password: [{ required: true, message: 'Please enter the old password', trigger: 'change' }],
            newPassword: [{ required: true, message: 'Please enter a new password', trigger: 'change' }],
            newAgain: [{ required: true, validator: this.checkPwd, trigger: 'change' }]
        };
    }
    submit() {
        // 表单校验
        this.$refs.form.validate((valid) => {
            if (valid) {
                if (this.form.newAgain != this.form.newPassword) {
                    this.$notify.error('The two new passwords are inconsistent.');
                }
                else {
                    delete this.form.newAgain;
                    login_1.forgetPwd(this.form).then(() => {
                        this.$notify.success('Successfully modified.');
                        this.form.password = '';
                        this.form.newPassword = '';
                        this.form.newAgain = '';
                    });
                }
            }
        });
    }
    mounted() {
    }
};
Password = __decorate([
    vue_property_decorator_1.Component
], Password);
exports.default = Password;
