"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const vue_property_decorator_1 = require("vue-property-decorator");
const home_1 = require("@/api/home");
let FooterTop = class FooterTop extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.content = '';
        this.imgList = [
            {
                link: 'https://www.facebook.com/cnSunnyElevator/',
                url: require('../../assets/FB.png')
            },
            {
                link: 'https://www.linkedin.cn/incareer/home',
                url: require('../../assets/INS.png')
            }
        ];
    }
    submitHelp() {
        const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        if (!reg.test(this.content)) {
            this.$notify.error('请输入正确的邮箱');
            return;
        }
        home_1.help({ content: this.content }).then(res => {
            this.$notify.success('Thank you for your trust and we will contact you as soon as possible.');
        });
    }
};
FooterTop = __decorate([
    vue_property_decorator_1.Component({
        components: {}
    })
], FooterTop);
exports.default = FooterTop;
