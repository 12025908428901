<template>
     <el-dialog title="Gear Config" :visible.sync="showgoods08" width="800px" append-to-body :close-on-click-modal='false' height='500' @close='cancel'>
      <div style="height:600px;overflow: auto">
        <el-form :model="form08"  class="form05" label-width="120px">
          <el-form-item label="As One">
            <el-radio-group v-model="form08.asOne">
              <el-radio label="Yes" :value='1'></el-radio>
              <el-radio label="No" :value='0'></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="wheel">
            <el-select v-model="form08.wheel" placeholder="Please select" style="width: 450px;" @change="change1">
             <el-option v-for="(item,index) in COPwaike" :key="index"  :value="`${item.specification}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="Null" value="''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="bearing">
            <el-select v-model="form08.bearing" placeholder="Please select" style="width: 450px;" filterable @change="change2">
              <el-option v-for="(item,index) in bearing" :key="index"  :value="`${item.specification}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
              </el-option>
               <el-option label="Null" value="''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Shaft length">
            <el-select v-model="form08.sl" placeholder="Please select" style="width: 450px;" filterable @change="change3">
              <el-option v-for="(item,index) in sl" :key="index"  :value="`${item.specification}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
              </el-option>
                <el-option label="Null" value="''"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Clip">
            <el-radio-group v-model="form08.check24">
              <el-radio :label="'M16U(φ50)'">M16U(φ50) +$5</el-radio>
              <el-radio :label="'M20(φ60)M20'">(φ60) +$7</el-radio>
              <el-radio :label="''">Null</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lopClick08">Confirm</el-button>
        <el-button @click="cancel">Cancel</el-button>
      </div>
    </el-dialog>
</template>
<script>
import { productSetList } from '@/api/productDetail'
export default {
  props: {
    itemobj: {
      type: Object,
      default: () => {
        return {}
      },
    },
    itemIndex: {
      type: Number,
      default: () => {
        return 0
      },
    },
    type: {
      type: String,
      default: () => {
        return ''
      },
    },
    wheelId: {
      type: String,
      default: () => {
        return ''
      },
    }
  },
  watch: {
    itemobj() {
      this.add()
    },
  },
  data() {
    return {
      showgoods08: true,
      form08: {
        asOne: 'Yes',
        wheel: '',
        bearing: '',
        sl: '',
        check24: ''
      },
      COPwaike: [],
      bearing: [],
      sl: [],
      lopButtonDesc01: '',
      lopButtonDesc02: '',
      lopButtonDesc03: '',
      haveColor01: '',
    }
  },
  mounted() {
    if (this.itemobj) {
      this.add()
    }else{
      this.$set(this.form08,'asOne','Yes')
    }
    //this.add()
     this.getparamsList()
     this.getbearingList()
     this.getslList()
  },
  methods: {
    change1(val){
          this.$set(this.form08,this.form08.wheel,val)
        },
    change2(val){
          this.$set(this.form08,this.form08.bearing,val)
        },
    change3(val){
          this.$set(this.form08,this.form08.sl,val)
        },
    add() {
      if (this.itemobj) {
        this.form08 = this.itemobj
        if(!this.form08.asOne){
          this.$set(this.form08,'asOne','Yes')
        }
      //  if(this.itemobj)
      }
    },
    getparamsList() {
      let params = {
        content:'导向轮'+' '+ this.wheelId,
        pageNo: 1,
        pageSize: 200,
      }
      productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.COPwaike.push({
              specification: this.type + ' ' + res.list[x].specification,
              engspecification: this.type + ' ' + res.list[x].engspecification,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].majorSupplierPrice,
            })
          }
          if(!this.form08.wheel)
          this.form08.wheel = this.COPwaike[0].specification+'||'+this.COPwaike[0].id;
        })
        .catch((err) => {})
    },
    getbearingList() {
      let params = {
        id:'B68DC164-CC73-DCB6-B8C6-59B1BFE3118B',
        content:'单品',
        pageNo: 1,
        pageSize: 200,
      }
      productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.bearing.push({
              specification: res.list[x].specification+' '+res.list[x].model,
              engspecification: res.list[x].engspecification+' '+res.list[x].model,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].majorSupplierPrice,
            })
          }
          if(!this.form08.bearing)
          this.form08.bearing = this.bearing[0].specification+'||'+this.bearing[0].id;
        })
        .catch((err) => {})
    },
    getslList() {
      let params = {
        id:'E414DFD4-2A96-AF1B-A95D-A0127D8E70F0',
        content:'单品',
        pageNo: 1,
        pageSize: 200,
      }
      productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.sl.push({
              specification: res.list[x].specification+' '+res.list[x].model,
              engspecification: res.list[x].engspecification+' '+res.list[x].model,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].majorSupplierPrice,
            })
          }
          if(!this.form08.sl)
          this.form08.sl = this.sl[0].specification+'||'+this.sl[0].id;
        })
        .catch((err) => {})
    },
    lopClick08() {
    let params={
        asOne:this.form08.asOne,
        wheel:this.form08.wheel,
        bearing:this.form08.bearing,
        sl:this.form08.sl,
        check24:this.form08.check24
    }
    let purchasePrice = 0;
    let wheelPrice = 0;
    if(params.wheel&&params.wheel!=''){
      let obj = this.COPwaike.find((item)=>{//这里的userList就是上面遍历的数据源
           return (item.specification+'||'+item.id) === params.wheel;//筛选出匹配数据
         });
         if(obj.price)
         wheelPrice = obj.price;
    }

    let bearingPrice = 0;
    if(params.bearing&&params.bearing!=''){
      let obj = this.bearing.find((item)=>{//这里的userList就是上面遍历的数据源
           return (item.specification+'||'+item.id) === params.bearing;//筛选出匹配数据
         });
         if(obj.price)
         bearingPrice = obj.price;
    }

    let slPrice = 0;
    if(params.sl&&params.sl!=''){
      let obj = this.sl.find((item)=>{//这里的userList就是上面遍历的数据源
           return (item.specification+'||'+item.id) === params.sl;//筛选出匹配数据
         });
         if(obj.price)
         slPrice = obj.price;
    }
    if(params.check24&&params.check24!=''){
      if(params.check24=='M16U(φ50)'){
        purchasePrice =  purchasePrice+5
      }else{
        purchasePrice =  purchasePrice+7
      }
      }
    purchasePrice = purchasePrice+wheelPrice+bearingPrice+slPrice;
    params.purchasePrice = purchasePrice.toFixed(2)
      params.index = this.itemIndex
      this.$emit('updata', params)
    },
    cancel() {
      this.$emit('cancel', true)
    }
  },
}
</script>
