"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const vue_property_decorator_1 = require("vue-property-decorator");
const order_1 = require("@/api/order");
const car_1 = require("@/api/car");
const store_1 = require("@/store");
let Create = class Create extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : '';
        this.show = false;
        this.portList = [];
        this.form = {
            remark: '',
            name: this.userInfo.name,
            address: '',
            email: this.userInfo.email,
            phone: this.userInfo.phone,
            destinationPort: '',
            deliveryDate: new Date(),
            payment: '',
            termOfPrice: '',
            loginToken: this.userInfo.loginToken
        };
        this.form2 = {
            "detailAddress": "222",
            "city": "2222",
            "country": "2222222",
            "customerContractId": "2222",
            "email": "22222",
            "isSelected": false,
            "loginToken": "string",
            "mobile": "string",
            "postalcode": "string",
            "receiverName": "string"
        };
        this.chosenAddressId = '1';
        this.dialogVisible2 = false;
        this.dialogVisible3 = false;
        this.list2 = [];
        this.columns = [];
        this.searchResult = [];
        this.isNew = false;
        this.selectAddress = {};
        this.paymentListResult = {};
    }
    ;
    addAddress() {
        order_1.getAddressList().then((res) => {
            if (res && res.length > 0) {
                this.list2 = [];
                res.map(item => {
                    this.list2.push({
                        id: item.id,
                        name: item.receiverName,
                        tel: item.mobile,
                        address: item.country ? item.country + ' ' + item.city + " " + item.detailAddress : '',
                        isDefault: item.isSelected
                    });
                });
                // console.log('selectAddress',this.selectAddress)
                if (!this.selectAddress.mobile) {
                    //如果没有默认地址就选第一条
                    let defailtAddress = res.filter((item) => item.isSelected == true);
                    if (defailtAddress.length == 0) {
                        defailtAddress.push(res[0]);
                    }
                    this.selectAddress = defailtAddress[0];
                }
            }
        });
    }
    sureDelete(id) {
        this.$confirm(this.$t('确认删除?'), this.$t('提示'), {
            confirmButtonText: this.$t('确定'),
            cancelButtonText: this.$t('取消'),
            type: 'warning'
        }).then(() => {
            order_1.getAddressDelete({ ids: id, loginToken: this.userInfo.loginToken }).then(() => {
                this.hamdleComment();
            });
        });
    }
    getCountryList() {
        order_1.getCountryList().then((res) => {
            this.columns = res;
        });
    }
    onAdd() {
        this.dialogVisible3 = true;
        this.isNew = true;
        this.form2 = {};
        if (this.$refs.form2)
            this.resetForm('form2');
    }
    resetForm(formName) {
        this.$refs[formName].resetFields();
    }
    onEdit(item) {
        this.isNew = false;
        order_1.getAddressDetail({ id: item.id, loginToken: this.userInfo.loginToken }).then((res) => {
            this.dialogVisible3 = true;
            this.form2 = res;
        });
    }
    onSelect(item, index) {
        // console.log(item)
        order_1.getAddressDetail({ id: item.id, loginToken: this.userInfo.loginToken }).then((res) => {
            this.selectAddress = res;
            this.dialogVisible2 = false;
        });
    }
    hamdleComment() {
        this.$message.success('操作成功');
        this.dialogVisible3 = false;
        this.addAddress();
    }
    submitAddress(formName) {
        this.$refs[formName].validate((valid) => {
            // console.log(valid)
            // console.log(this.isNew)
            if (valid) {
                this.form2.loginToken = this.userInfo.loginToken;
                if (this.isNew) {
                    order_1.getAddressInsert(this.form2).then(() => {
                        this.hamdleComment();
                    }).catch(error => { this.$message.error(error.data.msg); });
                }
                else {
                    order_1.getAddressModify(Object.assign({}, this.form2)).then(() => {
                        this.hamdleComment();
                    }).catch(error => { this.$message.error(error.data.msg); });
                }
            }
        });
    }
    submit() {
        if (!this.form.name) {
            return this.$message.error('your information is required');
        }
        if (!this.form.deliveryDate) {
            return this.$message.error('deliveryDate is required');
        }
        if (!this.form.address) {
            return this.$message.error('Receiving address is required');
        }
        if (this.num) {
            for (const key in this.list) {
                // 删除disperity属性
                delete this.list[key].disperity;
            }
            this.list = this.list.filter(item => item.id && item.actualPrice);
        }
        // const req = {
        //   address: this.form.address,
        //   deliveryDate: this.form.deliveryDate,
        //   destinationPort: this.form.destinationPort,
        //   email: this.form.email,
        //   itemList: this.list,
        //   loginToken: this.userInfo.loginToken,
        //   name: this.form.name,
        //   phone: this.form.phone,
        //   remark: this.form.remark
        // }
        this.totalPrice > 10000 ? this.form.payDiscuss = true : this.form.payDiscuss = false;
        const req = Object.assign(Object.assign({}, this.form), { itemList: this.list });
        this.hasNum(this.num, req);
    }
    submit222() {
        if (!this.form.name) {
            return this.$message.error('your information is required');
        }
        if (!this.form.deliveryDate) {
            return this.$message.error('deliveryDate is required');
        }
        this.$refs.form.validate((valid) => {
            if (this.num) {
                for (const key in this.list) {
                    // 删除disperity属性
                    delete this.list[key].disperity;
                }
                this.list = this.list.filter(item => item.id && item.actualPrice);
            }
            if (valid) {
                // const req = {
                //   address: this.form.address,
                //   deliveryDate: this.form.deliveryDate,
                //   destinationPort: this.form.destinationPort,
                //   email: this.form.email,
                //   itemList: this.list,
                //   loginToken: this.userInfo.loginToken,
                //   name: this.form.name,
                //   phone: this.form.phone,
                //   remark: this.form.remark
                // }
                const req = Object.assign(Object.assign({}, this.form), { itemList: this.list });
                if (this.totalPrice > 10000) {
                    const sure = this.$t('给业务员留言');
                    const tip = this.$t('提示');
                    const confrom = this.$t('确定');
                    const cancel = this.$t('取消');
                    this.$prompt(sure, tip, {
                        confirmButtonText: confrom,
                        cancelButtonText: cancel,
                    }).then((value, error) => {
                        this.hasNum(this.num, req);
                    }).catch(() => {
                        this.hasNum(this.num, req);
                    });
                }
                else {
                    this.hasNum(this.num, req);
                }
            }
        });
    }
    hasNum(num, req) {
        if (num) { //自主装机
            order_1.getInsertBySet(req).then(() => {
                this.$message.success('Operation succeeded');
                this.$router.push('/order');
            });
        }
        else { //购物车订单
            order_1.submitOrder(req).then(() => {
                this.$message.success('Operation succeeded');
                let carNum = localStorage.getItem('carNum') - this.list.length;
                localStorage.setItem('carNum', carNum);
                this.setCarNum(carNum);
                this.$router.push('/order');
            });
        }
    }
    mounted() {
        car_1.baseList({ code: '目的口岸' }).then(res => {
            this.portList = res;
        });
        order_1.paymentList({ loginToken: this.userInfo.loginToken }).then(res => {
            // console.log(res)
            if (res.termOfPrice) {
                this.form.termOfPrice = res.termOfPrice;
            }
            else {
                this.form.termOfPrice = res.termOfPriceList[0].ename;
            }
            if (res.payment) {
                this.form.payment = res.payment;
            }
            else {
                this.form.payment = res.paymentList[0].payment;
            }
            this.paymentListResult = res;
        });
        this.addAddress();
        this.getCountryList();
    }
    onChangeValue(newVal) {
        // console.log('监听地址',newVal)
        if (newVal.mobile) {
            this.form.name = newVal.receiverName;
            this.form.email = newVal.email;
            this.form.phone = newVal.mobile;
            this.form.address = newVal.country ? newVal.country + " " + newVal.city + " " + newVal.detailAddress : '';
            this.form.destinationPort = newVal.destinationPort;
            this.chosenAddressId = newVal.id;
        }
    }
    updateInput(val) {
        if (val !== this.show) {
            this.show = val;
        }
    }
    updateValue(val) {
        if (val !== this.visible) {
            this.$emit('update:visible', val);
        }
    }
};
__decorate([
    store_1.AppModule.Mutation
], Create.prototype, "setCarNum", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], Create.prototype, "list", void 0);
__decorate([
    vue_property_decorator_1.Prop({ default: false })
], Create.prototype, "visible", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], Create.prototype, "totalPrice", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], Create.prototype, "num", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], Create.prototype, "yuanjia", void 0);
__decorate([
    vue_property_decorator_1.Watch('selectAddress', { immediate: true, deep: true })
], Create.prototype, "onChangeValue", null);
__decorate([
    vue_property_decorator_1.Watch('visible', { immediate: true })
], Create.prototype, "updateInput", null);
__decorate([
    vue_property_decorator_1.Watch('show')
], Create.prototype, "updateValue", null);
Create = __decorate([
    vue_property_decorator_1.Component
], Create);
exports.default = Create;
