"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateStr = void 0;
function getDateStr(seconds) {
    const date = new Date(seconds);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    const currentTime = year + '-' + month + '-' + day;
    // const currentTime = year + '-' + month + '-' + day + '  ' + hour + ':' + minute + ':' + second
    return currentTime;
}
exports.getDateStr = getDateStr;
