"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AppState {
    constructor() {
        this.expand = true;
        this.userInfo = {};
        this.homeActiveIndex = '1';
        this.firstCategory = '';
        this.firstCategoryId = '';
        this.secondCategory = '';
        this.secondCategoryId = '';
        this.carNum = 0;
        this.appSearch = '';
        this.allTypes = [];
    }
}
const app = {
    namespaced: true,
    state: new AppState(),
    mutations: {
        setExpand(state, expand) {
            state.expand = expand;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setHomeActiveIndex(state, homeActiveIndex) {
            state.homeActiveIndex = homeActiveIndex;
        },
        setFirstCategory(state, firstCategory) {
            state.firstCategory = firstCategory;
        },
        setFirstCategoryId(state, firstCategoryId) {
            state.firstCategoryId = firstCategoryId;
        },
        setSecondCategory(state, secondCategory) {
            state.secondCategory = secondCategory;
        },
        setSecondCategoryId(state, secondCategoryId) {
            state.secondCategoryId = secondCategoryId;
        },
        setCarNum(state, carNum) {
            state.carNum = carNum;
        },
        setAppSearch(state, appSearch) {
            state.appSearch = appSearch;
        },
        setAllTypes(state, allTypes) {
            state.allTypes = allTypes;
        }
    },
    getters: {},
    actions: {}
};
exports.default = app;
