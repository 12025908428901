"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let SalesMan = class SalesMan extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
    handleClose() {
        this.$emit('closeDialog'); // 取消和 x 按钮的事件，防止重复操作createDialog变量
    }
};
__decorate([
    vue_property_decorator_1.Prop()
], SalesMan.prototype, "contacInfo", void 0);
__decorate([
    vue_property_decorator_1.Prop(Boolean)
], SalesMan.prototype, "dialogVisible", void 0);
SalesMan = __decorate([
    vue_property_decorator_1.Component({
        components: {}
    })
], SalesMan);
exports.default = SalesMan;
