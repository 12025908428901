<template>
  <!-- cop套装产品配置 -->
  <el-dialog title="COP config" :visible.sync="showgoods01" width="600px" append-to-body :close-on-click-modal='false' height='500' @close='cancel'>
    <div style="height:600px;overflow: auto">
      <el-form :model="form07" class="form05" label-width="120px">
        <el-form-item label="as one">
          <el-radio-group v-model="form07.asOne">
            <el-radio label="Yes" value='是'></el-radio>
            <el-radio label="No" value='否'></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="COP shell">
          <el-select v-model="form07.cop" placeholder="Please select" style="width:400px">
            <el-option v-for="(item,index) in COPwaike" :key="index" :value="`${item.specification}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="''" value="无"></el-option>
          </el-select>
          <!-- <el-input v-model="buttonText08" placeholder="Please select" style="display:inline-block;width:200px" /> -->
          <!-- <el-button @click.prevent="showParamsEvent(3)" style="display:inline-block;margin-left:10px">Please select</el-button> -->
        </el-form-item>
        <el-form-item label="stop">
          <el-input v-model="form07.floor" placeholder="Please input" @change="setButtonNum"/>
        </el-form-item>
        <el-form-item label="stop num">
          <el-input v-model="form07.floorContent" placeholder="Please input" />
        </el-form-item>
        <el-form-item label="COP Button">
          <el-select v-model="form07.button" placeholder="Please select" style="width:400px" filterable >
            <el-option v-for="(item,index) in copanniu" :key="index"  :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="''" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Button Color">
          <el-radio-group v-model="form07.lopButtonDesc01">
            <el-radio label="blue" value='蓝光'></el-radio>
            <el-radio label="red" value='红光'></el-radio>
            <el-radio label="orange" value='橙光'></el-radio>
            <el-radio label="white" value='白光'></el-radio>
            <el-radio label="green" value='绿光'></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Button Voltage">
          <el-radio-group v-model="form07.lopButtonDesc02">
            <el-radio label="12v" value='12v'></el-radio>
            <el-radio label="24v" value='24v'></el-radio>
            <el-radio label="36v" value='36v'></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="braille">
          <el-radio-group v-model="form07.lopButtonDesc03">
            <el-radio label="have braille" value='带盲文'></el-radio>
            <el-radio label="no braille" value='不带盲文'></el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="按钮描述">
            <el-input v-model="form05.lopButtonDesc" placeholder="Please input" type="textarea" disabled />
          </el-form-item> -->
        <el-form-item label="Button Num">
          <el-input v-model="form07.buttonNums" placeholder="Please input" />
        </el-form-item>
        <el-form-item label="COP display">
          <el-select v-model="form07.display" placeholder="Please select" style="width:400px" filterable>
            <el-option v-for="(item,index) in copxianshi" :key="index" :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`" >
            </el-option>
             <el-option label="''" value="无"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="显示描述">
            <el-input v-model="form05.resource" placeholder="Please input" type="textarea" disabled />
          </el-form-item> -->
        <el-form-item label="Control panel">
          <el-radio-group v-model="form07.ccb">
            <el-radio :label="'MCTC-CCB-A||SN-01200005'">MONARCH</el-radio>
            <el-radio :label="'SM.02/G||SN-01190003&&SM-03-D||SN-01190005'">STEP</el-radio>
            <el-radio :label="''">Null</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Dashboard Des">
          <el-input v-model="form07.ccbDesc" placeholder="Please input" type="textarea" />
        </el-form-item>
        <el-form-item label="plating">
          <el-radio-group v-model="form07.haveColor">
            <el-radio label="+$10(One+$0.5)" value='+50RMB(单个按钮+3RMB)'></el-radio>
            <el-radio :label="''">Null</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="plating color">
          <el-input v-model="form07.haveColor01" placeholder="Please input" />
        </el-form-item>
        <el-form-item label="+LOGO">
          <el-radio-group v-model="form07.haveLogo">
            <el-radio label="+$5" value='+20RMB'></el-radio>
            <el-radio :label="''">Null</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="lopClick01">Confirm</el-button>
      <el-button @click="cancel">Cancel</el-button>
    </div>
  </el-dialog>
  <!-- cop套装产品配置 -->
</template>
<script>
import { productSetList } from '@/api/productDetail'
export default {
  props: {
    itemobj: {
      type: Object,
      default: () => {
        return {}
      },
    },
    itemIndex: {
      type: Number,
      default: () => {
        return 0
      },
    },
    type: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  watch: {
    itemobj() {
      this.add()
    },
    type() {
     // this.getparamsList()
    },
  },
  data() {
    return {
      showgoods01: true,
      form07: {
        asOne: '是',
        cop: '',
        button: '',
        display: '',
        ccb: null,
        buttonNums: '',
        floorContent: '',
        haveLogo: '',
        haveColor: '',
        haveColor01:'',
        floor: '',
        copButtonDesc: '',
        copDisplayDesc: '',
        ccbDesc: '',
        lopDisplayDesc: '',
        lopButtonDesc03:'',
        lopButtonDesc01:'',
        lopButtonDesc02:''
      },
      COPwaike: [],
      copanniu: [],
      copxianshi: [],
    }
  },
  mounted() {
    if (this.itemobj) {
      this.add()
    }else{
      this.$set(this.form07,'asOne','Yes')
    }
    //this.add()
    this.getparamsList()
    this.getbtn()
    this.getDisplay()

  },
  methods: {
    setButtonNum(){
      this.$set(this.form07,'buttonNums',Number(this.form07.floor) +4)
    },
    add() {
      if (this.itemobj) {
        this.form07 = this.itemobj
        if (this.itemobj.lopButtonDesc) {
        //   this.lopButtonDesc01 = this.itemobj.lopButtonDesc.split(' ')[0]
        //   this.lopButtonDesc02 = this.itemobj.lopButtonDesc.split(' ')[1]
        //   this.lopButtonDesc03 = this.itemobj.lopButtonDesc.split(' ')[2]
         this.form07.asOne= this.itemobj.asOne
        this.form07.cop= this.itemobj.cop
        this.form07.button= this.itemobj.button
        this.form07.display= this.itemobj.display
        this.form07.buttonNums= this.itemobj.buttonNums
        this.form07.haveLogo= this.itemobj.haveLogo
        this.form07.haveColor= this.itemobj.haveColor
        this.form07.lopDisplayDesc= this.itemobj.lopDisplayDesc
        this.form07.lopButtonDesc01= this.itemobj.lopButtonDesc01
        this.form07.lopButtonDesc02= this.itemobj.lopButtonDesc02
        this.form07.lopButtonDesc03= this.itemobj.lopButtonDesc03
        this.form07.haveColor01= this.itemobj.haveColor01
        this.form07.floorContent=this.itemobj.floorContent
        this.form07.floor=this.itemobj.floor
        this.form07.ccb=this.itemobj.ccb
        this.form07.ccbDesc=this.itemobj.ccbDesc
        }
        if(!this.form07.asOne){
          this.$set(this.form07,'asOne','Yes')
        }
      }else{
       this.form07.asOne= 'Yes'
        this.form07.cop= ''
        this.form07.button= ''
        this.form07.display= ''
        this.form07.buttonNums= ''
        this.form07.haveLogo= ''
        this.form07.haveColor= ''
        this.form07.lopDisplayDesc= ''
        this.form07.lopButtonDesc01= ''
        this.form07.lopButtonDesc02= ''
        this.form07.lopButtonDesc03= ''
        this.form07.haveColor01= ''
        this.form07.floorContent=''
        this.form07.floor=''
        this.form07.ccb=''
        this.form07.ccbDesc=''
      }
    },
    getparamsList() {
      let params = {
        content: this.type,
        pageNo: 1,
        pageSize: 200,
      }
     productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.COPwaike.push({
              specification: this.type + ' ' + res.list[x].specification,
              engspecification:this.type + ' ' + res.list[x].engspecification,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].actualPrice,
            })
          }
        })
        .catch((err) => {})
    },
    getbtn() {
      let params = {
        pageNo: 1,
        pageSize: 200,
        content: '显示',
        //id: '26E7BF2B-1BB8-C0B3-95D8-4725C7E85C42',
      }
      productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.copxianshi.push({
              specification: res.list[x].model+ ' ' + res.list[x].specification,
              engspecification: res.list[x].model+ ' ' + res.list[x].engspecification,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].actualPrice,
            })
          }
        })
        .catch((err) => {})
    },
    getDisplay(){
        let params = {
        pageNo: 1,
        pageSize: 200,
        content: '',
        id: 'F22F07D5-DA23-35BF-0893-379D83A9F711',
      }
     productSetList(params).then((res) => {
          for (let x = 0; x < res.list.length; x++) {
            this.copanniu.push({
              specification:  res.list[x].model + ' ' + res.list[x].engspecification,
              engspecification: res.list[x].model+ ' ' + res.list[x].engspecification,
              id: res.list[x].id,
              model:res.list[x].model,
              price:res.list[x].actualPrice,
            })
          }
        })
        .catch((err) => {})
    },
    lopClick01() {

      let params = {
        asOne: this.form07.asOne?this.form07.asOne:'',
        cop: this.form07.cop==''||this.form07.cop==undefined?'':this.form07.cop,
        button: this.form07.button==''||this.form07.button==undefined?'':this.form07.button,
        display: this.form07.display==''||this.form07.display==undefined?'':this.form07.display,
        buttonNums:this.form07.buttonNums==''||this.form07.buttonNums==undefined?0:this.form07.buttonNums,
        haveLogo:this.form07.haveLogo==''||this.form07.haveLogo==undefined?'':this.form07.haveLogo,
        haveColor:this.form07.haveColor==''||this.form07.haveColor==undefined?'':this.form07.haveColor,
        lopDisplayDesc: this.form07.lopDisplayDesc==''||this.form07.lopDisplayDesc==undefined?'':this.form07.lopDisplayDesc,
        lopButtonDesc01: this.form07.lopButtonDesc01==''||this.form07.lopButtonDesc01==undefined?'':this.form07.lopButtonDesc01,
        lopButtonDesc02: this.form07.lopButtonDesc02==''||this.form07.lopButtonDesc02==undefined?'':this.form07.lopButtonDesc02,
        lopButtonDesc03: this.form07.lopButtonDesc03==''||this.form07.lopButtonDesc03==undefined?'':this.form07.lopButtonDesc03,
        haveColor01: this.form07.haveColor01==''||this.form07.haveColor01==undefined?'':this.form07.haveColor01,
        floorContent:this.form07.floorContent==''||this.form07.floorContent==undefined?'':this.form07.floorContent,
        floor:this.form07.floor==''||this.form07.floor==undefined?'':this.form07.floor,
        ccb:this.form07.ccb==''||this.form07.ccb==undefined?'':this.form07.ccb,
        ccbDesc:this.form07.ccbDesc==''||this.form07.ccbDesc==undefined?'':this.form07.ccbDesc,

      }

      let purchasePrice = 0;
      let copprice = 0;
      if(params.cop&&params.cop!=''){
        let obj = this.COPwaike.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.specification+'||'+item.id) === params.cop;//筛选出匹配数据
           });
           if(obj.price)
           copprice = obj.price;
      }
      let butprice = 0;

      if(params.button&&params.button!=''){
        let obj = this.copanniu.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.button;//筛选出匹配数据
           });
           if(obj.price&&params.buttonNums)
           butprice = obj.price*params.buttonNums;
      }
      let disprice = 0;
      if(params.display&&params.display!='无'){
        let obj = this.copxianshi.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.display;//筛选出匹配数据
           });
           if(obj.price)
           disprice = obj.price;
      }
      params.index = this.itemIndex
      purchasePrice = copprice+butprice+disprice;
      if (params.haveLogo != '') {
        purchasePrice =  purchasePrice+5
      }
      if(params.haveColor != ''){
         purchasePrice =  purchasePrice+10
      }
      if(params.ccb!=''){
        if(params.ccb=='MCTC-CCB-A||SN-01200005'){
           purchasePrice =  purchasePrice+7
        }else{
           purchasePrice =  purchasePrice+30
        }

      }
      params.purchasePrice = purchasePrice
      this.$emit('updata', params)
    },
    cancel() {
      this.$emit('cancel', true)
    },
  },
}
</script>
