"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const vue_property_decorator_1 = require("vue-property-decorator");
let Config = class Config extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.show = false;
        this.form = {
            address: '',
            deliveryDate: '',
            destinationPort: '',
            email: '',
            itemList: '',
            loginToken: this.userInfo.loginToken,
            name: '',
            phone: ''
        };
    }
    submit() {
        //   submitOrder(this.form).then(() => {
        //     this.$router.push('/order')
        //   })
        this.show = false;
        this.$emit('update:visible', false);
    }
    updateInput(val) {
        if (val !== this.show) {
            this.show = val;
        }
    }
    updateValue(val) {
        if (val !== this.visible) {
            this.$emit('update:visible', val);
        }
    }
};
__decorate([
    vue_property_decorator_1.Prop({ default: false })
], Config.prototype, "visible", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], Config.prototype, "detail", void 0);
__decorate([
    vue_property_decorator_1.Watch('visible', { immediate: true })
], Config.prototype, "updateInput", null);
__decorate([
    vue_property_decorator_1.Watch('show')
], Config.prototype, "updateValue", null);
Config = __decorate([
    vue_property_decorator_1.Component
], Config);
exports.default = Config;
