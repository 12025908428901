"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dictionary_1 = require("@/config/dictionary");
function default_1(value, code) {
    if (!value)
        return '';
    const map = dictionary_1.getDictionary(code) || {};
    return map[value] || value;
}
exports.default = default_1;
