"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const options = [
    // {
    //   name: 'productType',
    //   title: '看板',
    //   router: true
    // },
    // {
    //   name: 'example',
    //   title: '页面示例',
    //   children: [
    //     {
    //       name: 'book',
    //       title: '图书管理',
    //       router: true,
    //       children: [
    //         {
    //           name: 'detail',
    //           title: '详情-{id}',
    //           router: { pathSuffix: '/:id', nested: false }
    //         },
    //         {
    //           name: 'create',
    //           title: '创建',
    //           router: { nested: false }
    //         }
    //       ]
    //     }
    //   ]
    // }
    {
        name: 'home',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'goodDetail',
        title: '',
        router: { pathSuffix: '/:id', nested: false }
    },
    {
        name: 'forgetPwd',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'register',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'order',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'orderDetail',
        title: '',
        router: { pathSuffix: '/:id', nested: false }
    },
    {
        name: 'brand',
        title: '',
        router: { pathSuffix: '/:id/', nested: false }
    },
    {
        name: 'hotProduct',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'car',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'about',
        title: '',
        router: { pathSuffix: '/:id', nested: false }
    },
    {
        name: 'config',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'types',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'diy',
        title: '',
        router: { pathSuffix: '', nested: false }
    },
    {
        name: 'news',
        title: '',
        router: { pathSuffix: '/:id', nested: false }
    }
];
exports.default = options;
