"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const vue_property_decorator_1 = require("vue-property-decorator");
let DataIterator = class DataIterator extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.page = 1;
        this.size = 10;
        this.sorter = null;
        this.params = {};
        this.localRows = [];
        this.localTotal = 0;
        this.loading = false;
        this.seq = 1;
    }
    get actualPagedLoader() {
        if (this.api) {
            return ({ page, size, params }) => this.$http.post(this.api, Object.assign(Object.assign({}, params), { pageNum: page, pageSize: size })).then(data => Object.assign({ page: data.pageNum, size: data.pageSize }, data));
        }
        return this.pagedLoader;
    }
    get rows() {
        const data = this.dataSource || this.localRows || [];
        if (this.actualPagedLoader) {
            return data;
        }
        else {
            const start = (this.page - 1) * this.size;
            return this.pageable ? data.slice(start, start + this.size) : data;
        }
    }
    set rows(rows) {
        this.localRows = rows;
    }
    get total() {
        if (this.dataSource) {
            return this.dataSource.length;
        }
        else if (this.loader) {
            return this.localRows.length;
        }
        else if (this.actualPagedLoader) {
            return this.localTotal;
        }
        else {
            return 0;
        }
    }
    set total(total) {
        this.localTotal = total;
    }
    query(params = {}) {
        this.params = params;
        this.sorter = null;
        this.load(params, 1, this.size);
    }
    refresh() {
        this.load(this.params, this.page, this.size);
    }
    load(params = {}, page, size) {
        if (this.dataSource) {
            this.page = page;
            this.size = size;
        }
        else if (this.loader) {
            const seq = this.seq = this.seq + 1;
            this.loading = true;
            this.loader(params).then(data => {
                if (seq === this.seq) {
                    this.rows = data;
                    this.page = page;
                    this.size = size;
                    this.loading = false;
                }
            }).catch((e) => {
                if (seq === this.seq) {
                    this.loading = false;
                }
            });
        }
        else if (this.actualPagedLoader) {
            const seq = this.seq = this.seq + 1;
            this.loading = true;
            this.actualPagedLoader({ params, page, size, sorter: this.sorter }).then(data => {
                if (seq === this.seq) {
                    const { page, size, total, rows } = data || {};
                    this.page = page || this.page;
                    this.size = size || this.size;
                    this.total = total || 0;
                    this.rows = rows || [];
                    this.loading = false;
                }
            }).catch((e) => {
                if (seq === this.seq) {
                    this.loading = false;
                }
            });
        }
        else {
            throw new Error('can\'t load data, must define one of [dataSource] [loader] [pagedLoader]');
        }
    }
    defaultSizeChange() {
        if (this.defaultSize)
            this.size = this.defaultSize;
    }
};
__decorate([
    vue_property_decorator_1.Prop(Array)
], DataIterator.prototype, "dataSource", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], DataIterator.prototype, "loader", void 0);
__decorate([
    vue_property_decorator_1.Prop()
], DataIterator.prototype, "pagedLoader", void 0);
__decorate([
    vue_property_decorator_1.Prop(String)
], DataIterator.prototype, "api", void 0);
__decorate([
    vue_property_decorator_1.Prop(Number)
], DataIterator.prototype, "defaultSize", void 0);
__decorate([
    vue_property_decorator_1.Prop({ type: Boolean, default: true })
], DataIterator.prototype, "pageable", void 0);
__decorate([
    vue_property_decorator_1.Watch('defaultSize', { immediate: true })
], DataIterator.prototype, "defaultSizeChange", null);
DataIterator = __decorate([
    vue_property_decorator_1.Component({
        components: {}
    })
], DataIterator);
exports.default = DataIterator;
