"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Auth {
    constructor(options = {}) {
        this.principle = null;
        this.options = options;
    }
    get username() {
        var _a, _b;
        return ((_b = (_a = this.principle) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.username) || '';
    }
    get cname() {
        var _a, _b;
        return ((_b = (_a = this.principle) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.cname) || '';
    }
    get roles() {
        var _a;
        return ((_a = this.principle) === null || _a === void 0 ? void 0 : _a.roles) || [];
    }
    get authorities() {
        var _a;
        return ((_a = this.principle) === null || _a === void 0 ? void 0 : _a.authorities) || [];
    }
    get authenticated() {
        return !!this.principle;
    }
    hasAnyRole(...roles) {
        return roles.some(v => this.roles.some(w => w.roleCode === v));
    }
    hasAllRole(...roles) {
        return roles.every(v => this.roles.some(w => w.roleCode === v));
    }
    // 登录
    login(params) {
        var _a, _b;
        if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.loginHandler))
            throw new Error('please specific loginHandler');
        return (_b = this.options) === null || _b === void 0 ? void 0 : _b.loginHandler(params);
    }
    // 前往登录页面
    toLogin(data, logout = false) {
        const url = logout ? '/login' : `/login?backUrl=${location.pathname}${location.search}`;
        window.location.assign(url);
    }
    // 控制访问
    access(pid) {
        if (this.authenticated) {
            const has = this.authorities.some(v => v.pid === pid);
            return Promise.resolve(has);
        }
        else {
            return new Promise((resolve, reject) => {
                this.loadPrinciple().then(() => {
                    const has = this.authorities.some(v => v.pid === pid);
                    resolve(has);
                }).catch((e) => {
                    reject(e);
                });
            });
        }
    }
    // 同步控制访问
    accessSync(pid) {
        return this.authorities.some(v => v.pid === pid);
    }
    // 退出
    logout() {
        var _a;
        if (!((_a = this.options) === null || _a === void 0 ? void 0 : _a.logoutHandler))
            throw new Error('please specific logoutHandler');
        this.options.logoutHandler().then(data => {
            this.toLogin(data, true);
        });
    }
    // 加载用户信息
    load(refresh = false) {
        if (this.authenticated && !refresh)
            return Promise.resolve(this.principle);
        return this.loadPrinciple();
    }
    loadPrinciple() {
        var _a;
        if ((_a = this.options) === null || _a === void 0 ? void 0 : _a.principleLoader) {
            return this.options.principleLoader().then(data => {
                this.principle = data;
                return this.principle;
            });
        }
        throw Error('please specific PrincipleLoader');
    }
}
exports.default = Auth;
