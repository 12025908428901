<template>
  <el-dialog title="Guide Config" :visible.sync="showgoods02" width="800px" append-to-body :close-on-click-modal='false' height='500' @close='cancel'>
    <div style="height:400px;overflow: auto">
      <el-form :model="form05" class="form05" label-width="120px">
        <el-form-item label="Guide">
          <el-select v-model="form05.guideRail" placeholder="Please select" style="width: 450px;">
            <!-- <el-option label="导轨 82*62*15.88mm SN-T82-3/B pcs : 1; Fish Plate-T82-3/B Fish Plate-T82-3/B pcs : 1; M14 T-Clips-T82-3/B pcs : 0; M12*35 Bolts-T82-3/B pcs : 8;  SN-T82-3/B" value="导轨 82*62*15.88mm SN-T82-3/B pcs : 1; Fish Plate-T82-3/B Fish Plate-T82-3/B pcs : 1; M14 T-Clips-T82-3/B pcs : 0; M12*35 Bolts-T82-3/B pcs : 8;  SN-T82-3/B"></el-option> -->
            <el-option v-for="(item,index) in COPwaike" :key="index"  :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="Null" value="''"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Guide Num">
          <el-input v-model="form05.guideRailNums" placeholder="Please input" style="width: 450px;" @input="forceUpdate"/>
        </el-form-item>
        <el-form-item label="FishPlate">
          <el-select v-model="form05.fishPlate" placeholder="Please select" style="width: 450px;" filterable>
            <!-- <el-option label="连接板 Fish Plate-T82-3/B Fish Plate-T82-3/B" value="连接板 Fish Plate-T82-3/B Fish Plate-T82-3/B"></el-option> -->
            <el-option v-for="(item,index) in copanniu" :key="index" :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`" >
            </el-option>
             <el-option label="Null" value="''"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="FishPlate Num">
          <el-input v-model="form05.fishPlateNums" placeholder="Please input" style="width: 450px;" @input="forceUpdate"/>
        </el-form-item>
        <el-form-item label="TClips">
          <el-select v-model="form05.tClips" placeholder="Please select" style="width: 450px;" filterable>
            <!-- <el-option label="压导板 M14 T-Clips-T82-3/B" value="压导板 M14 T-Clips-T82-3/B"></el-option> -->
            <el-option v-for="(item,index) in copxianshi" :key="index"  :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="TClips Num">
          <el-input v-model="form05.tClipsNums" placeholder="Please input" style="width: 450px;" @input="forceUpdate"/>
        </el-form-item>
        <el-form-item label="Bolts">
          <el-select v-model="form05.bolts" placeholder="Please select" style="width: 450px;" filterable>
            <!-- <el-option label="螺栓组 M12*35 Bolts-T82-3/B" value="螺栓组 M12*35 Bolts-T82-3/B"></el-option> -->
             <el-option v-for="(item,index) in luoxuanban" :key="index"  :value="`${item.model}||${item.id}`" :label="`${item.engspecification}||${item.price}USD`">
            </el-option>
             <el-option label="无" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Bolts Num">
          <el-input v-model="form05.boltsNums" placeholder="Please input" style="width: 450px;" @input="forceUpdate"/>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="lopClick02">Confirm</el-button>
      <el-button @click="cancel">Cancel</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { productSetList } from '@/api/productDetail'
export default {
  props: {
    itemobj: {
      type: Object,
      default: () => {
        return {}
      },
    },
    itemIndex: {
      type: Number,
      default: () => {
        return 0
      },
    },
     type: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  watch: {
    itemobj() {
      this.add()
    },
  },
  data() {
    return {
      showgoods02: true,
      form05: {
        guideRail: '',
        fishPlate: '',
        tClips: '',
        bolts: '',
        guideRailNums: '1',
        fishPlateNums: '1',
        tClipsNums: '6',
        boltsNums: '8',
      },
      COPwaike: [],
      copanniu: [],
      copxianshi: [],
      lopButtonDesc01: '',
      lopButtonDesc02: '',
      lopButtonDesc03: '',
      haveColor01: '',
      luoxuanban:[]
    }
  },
  mounted() {
    if (this.itemobj) {
      this.form05 = this.itemobj
      if(!this.form05.fishPlateNums){
        this.form05.fishPlateNums = 1
      }
      if(!this.form05.guideRailNums){
        this.form05.guideRailNums = 1
      }
      if(!this.form05.tClipsNums){
        this.form05.tClipsNums = 6
      }
      if(!this.form05.boltsNums){
        this.form05.boltsNums = 8
      }
    }
    this.getparamsList('导轨')
    this.getparamsList('连接板')
    this.getparamsList('压导板')
    this.getparamsList('螺栓组')
    //this.add()
    // this.getparamsList()
  },
  methods: {
    forceUpdate() {
       this.$forceUpdate()
     },
    add() {
      if (this.itemobj) {
        this.form05 = this.itemobj
      }
    },
    getparamsList(name) {
      let params = {
        content: name + ' ' + this.type.split('-')[1]+ ' ' +'单品',
        pageNo: 1,
        pageSize: 200,
      }
      this.COPwaike=[]
      this.copxianshi=[]
      this.copanniu=[]
      this.luoxuanban=[]
      productSetList(params).then((res) => {
          if (name == '导轨') {
            for (let x = 0; x < res.list.length; x++) {
              if(res.list[x].chnitemName=='导轨'){
                this.COPwaike.push({
                 specification:res.list[x].specification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                 engspecification:res.list[x].engspecification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                  id: res.list[x].id,
                  model:res.list[x].model,
                  price:res.list[x].actualPrice,
                })
              }
            }
            if(!this.form05.guideRail)
            this.form05.guideRail = this.COPwaike[0].model+'||'+this.COPwaike[0].id;
          } else if (name == '压导板') {
            for (let x = 0; x < res.list.length; x++) {
              this.copxianshi.push({
                engspecification: res.list[x].engspecification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                specification: res.list[x].specification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                id: res.list[x].id,
                model:res.list[x].model,
                price:res.list[x].actualPrice,
              })
            }
            if(!this.form05.tClips)
            this.form05.tClips = this.copxianshi[0].model+'||'+this.copxianshi[0].id;
          } else if(name=='连接板') {
            for (let x = 0; x < res.list.length; x++) {
              this.copanniu.push({
               engspecification: res.list[x].engspecification+' '+res.list[x].engitemName+'-'+res.list[x].model,
               specification: res.list[x].specification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                id: res.list[x].id,
                model:res.list[x].model,
                price:res.list[x].actualPrice,
              })
            }
            if(!this.form05.fishPlate)
            this.form05.fishPlate = this.copanniu[0].model+'||'+this.copanniu[0].id;
          }else{
              for (let x = 0; x < res.list.length; x++) {
              this.luoxuanban.push({
                engspecification: res.list[x].engspecification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                specification: res.list[x].specification+' '+res.list[x].engitemName+'-'+res.list[x].model,
                id: res.list[x].id,
                model:res.list[x].model,
                price:res.list[x].actualPrice,
              })
            }
            if(!this.form05.bolts)
            this.form05.bolts = this.luoxuanban[0].model+'||'+this.luoxuanban[0].id;
          }
        })
        .catch((err) => {})
    },
    lopClick02() {
      let params={
          guideRail:this.form05.guideRail==null||this.form05.guideRail==undefined?'':this.form05.guideRail,
          guideRailNums:this.form05.guideRailNums==null||this.form05.guideRailNums==undefined?'':this.form05.guideRailNums,
          fishPlate:this.form05.fishPlate==null||this.form05.fishPlate==undefined?'':this.form05.fishPlate,
          fishPlateNums:this.form05.fishPlateNums==null||this.form05.fishPlateNums==undefined?'':this.form05.fishPlateNums,
          tClips:this.form05.tClips==null||this.form05.tClips==undefined?'':this.form05.tClips,
          tClipsNums:this.form05.tClipsNums==null||this.form05.tClipsNums==undefined?'':this.form05.tClipsNums,
           bolts:this.form05.bolts==null||this.form05.bolts==undefined?'':this.form05.bolts,
          boltsNums:this.form05.boltsNums==null||this.form05.boltsNums==undefined?'':this.form05.boltsNums,
      }
      params.index = this.itemIndex

      let purchasePrice = 0;
      let guideRailPrice = 0;
      if(params.guideRail&&params.guideRail!='无'&&params.guideRailNums&&params.guideRailNums>0){
        let obj = this.COPwaike.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.guideRail;//筛选出匹配数据
           });
           if(obj.price)
           guideRailPrice = obj.price*params.guideRailNums;
      }
      let fishPlateprice = 0;

      if(params.fishPlate&&params.fishPlate!=''&&params.fishPlateNums&&params.fishPlateNums>0){
        let obj = this.copanniu.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.fishPlate;//筛选出匹配数据
           });
           if(obj.price)
           fishPlateprice = obj.price*params.fishPlateNums;
      }
      let tClipsprice = 0;
      if(params.tClips&&params.tClips!=''&&params.tClipsNums&&params.tClipsNums>0){
        let obj = this.copxianshi.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.tClips;//筛选出匹配数据
           });
           if(obj.price)
           tClipsprice = obj.price*params.tClipsNums;
      }

      let boltsprice = 0;
      if(params.bolts&&params.bolts!=''&&params.boltsNums&&params.boltsNums>0){
        let obj = this.luoxuanban.find((item)=>{//这里的userList就是上面遍历的数据源
             return (item.model+'||'+item.id) === params.bolts;//筛选出匹配数据
           });
           if(obj.price)
           boltsprice = obj.price*params.boltsNums;
      }
      purchasePrice = guideRailPrice+fishPlateprice+tClipsprice+boltsprice;

      params.purchasePrice = purchasePrice.toFixed(2)
      this.$emit('updata', params)
    },
    cancel() {
      this.$emit('cancel', true)
    },
  },
}
</script>
