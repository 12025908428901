"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-nocheck
const vue_property_decorator_1 = require("vue-property-decorator");
let About = class About extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.form = {
            roping: '2:1',
            purpose: 'Normal Elevator',
            weight: '500',
            speed: '2',
            isMr: '有',
            stop: '4',
            travelHeight: '12000',
            grade: '',
            type: '',
            oh: 1500,
            pit: 4500
        };
        this.ropingOptions = [{ value: '2:1' }, { value: '1:1' }];
        this.purposeOptions = [{ value: 'Normal Elevator' }, { value: 'Freight Elevator' }, { value: 'Dumbwaiter Elevator' },];
        this.mrOptions = [{ value: '有|无', label: 'ALL' }, { value: '有', label: 'YES' }, { value: '无', label: 'NO' },];
    }
    onChangeTravelHeightValue(newValue, oldValue) {
        this.form.travelHeight = (newValue - 1) * 3000;
    }
    submitForm() {
        this.$refs.form.validate((valid) => {
            if (valid) {
                this.form.loginToken = this.userInfo.loginToken;
                sessionStorage.setItem('form', JSON.stringify(this.form));
                this.$router.push('/types');
            }
        });
    }
    mounted() {
    }
};
__decorate([
    vue_property_decorator_1.Watch('form.stop', { immediate: true, deep: false })
], About.prototype, "onChangeTravelHeightValue", null);
About = __decorate([
    vue_property_decorator_1.Component({
        components: { HeaderTop: () => Promise.resolve().then(() => __importStar(require('@/components/HeaderTop/HeaderTop.vue'))), FooterBottom: () => Promise.resolve().then(() => __importStar(require('@/components/FooterBottom/Foot.vue'))) }
    })
], About);
exports.default = About;
